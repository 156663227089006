import React from 'react';
import { Container, Grid, Paper, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, graphql, useStaticQuery } from 'gatsby';
import MNLogo from '../../images/svg/marchnetworks-logo.svg';
import WCLogo from '../../images/svg/wesleyclover-logo.svg';
import CGLogo from '../../images/svg/canopygrowth-logo.svg';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const caseStudies = [
  {
    heading: 'Get the Web Operations Team You Need',
    banner: 'usecase-01.jpg',
    logo: WCLogo,
    logoWidth: 230,
    logoHeight: 79,
    link: '/case-study/wesley-clover/',
    problem: {
      heading:
        'You need a new website or your old site needs a refresh. And you need it now.',
      description:
        'Your team doesn’t have the time or in-house skills to take a website from concept to reality in a compressed development cycle. Plus, you need to launch the site on a stable environment, and don’t want to worry about maintaining the site on an ongoing basis.',
    },
    solution: {
      heading:
        'Marvel & Snap can be the web operations team you need to get your site up and running fast.',
      description:
        'As an extension of your team, and under your direction, we will spearhead the development, launch, and ongoing management of your site — all for one set monthly fee.',
    },
  },
  {
    heading: 'Free up Your Marketing Team for Other Initiatives',
    banner: 'usecase-02.jpg',
    logo: MNLogo,
    logoWidth: 230,
    logoHeight: 61,
    link: '/case-study/march-networks/',
    problem: {
      heading:
        'You are happy with your current site, but maintaining it is taking up too much of your marketing team’s time.',
      description:
        'Although your team is able to handle day-to-day requirements, the focus on and expertise needed with web tools, techniques, and best practices is difficult to maintain.',
    },
    solution: {
      heading:
        'Marvel & Snap offers the web-focused knowledge and expertise needed to maintain your site, support your team’s online marketing efforts, and free up staff time for other projects.',
      description:
        'We’ll migrate your site to our secure hosting environment, so that uptime and speed will never be a concern. We’ll fulfill requests for ongoing iterations to the website quickly. And we’ll ensure your site leverages the latest technologies and best practices to speed up delivery of new online initiatives — all for one set monthly fee.',
    },
  },
  {
    heading: 'Strengthen the Team You Have',
    banner: 'usecase-03.jpg',
    logo: CGLogo,
    logoWidth: 187,
    logoHeight: 127,
    link: '/case-study/canopy-growth/',
    problem: {
      heading:
        'Your in-house development team has its hands full, and the to-do list is always increasing.',
      description:
        'The team you have is capable, but can use some extra strength to handle day-to-day development tasks. As project scopes increase, it’s unclear just how much ongoing development the in-house team can handle, and you’re not convinced hiring another team member is the most cost-effective solution.',
    },
    solution: {
      heading:
        'Marvel & Snap can provide the extra strength your in-house team needs, cost effectively.',
      description:
        'Under the direction of your web team, we will provide the agile resources needed to strengthen development processes, shorten development cycles, assist with maintenance, and enable continuous delivery of web services — all for one set monthly fee.',
    },
  },
];

const useCaseStudyStyles = makeStyles((theme) => ({
  caseStudyRoot: {
    position: 'relative',
    paddingBottom: 87,
    [theme.breakpoints.up('lg')]: {
      paddingBottom: 160,
    },
  },

  caseStudy: {
    position: 'relative',
    marginBottom: 87,
    [theme.breakpoints.up('lg')]: {
      marginBottom: 120,
    },
    '&:last-child': {
      marginBottom: 0,
    },
    '&__image': {
      opacity: 0.5,
    },
  },

  imageWrapper: {
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      width: 'calc(50vw - 130px)',
      overflow: 'hidden',
      maxHeight: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      width: 'calc(50vw - 200px)',
    },
  },

  imageRight: {
    [theme.breakpoints.up('lg')]: {
      left: 'unset',
      right: 0,
    },
  },

  bannerContent: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },

  callToAction: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textAlign: 'right',
    width: '60%',
    height: 125,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 80,
    },
    '& > p': {
      fontSize: 20,
      marginRight: 40,
    },
    '& > a': {
      width: '120px',
      backgroundColor: theme.palette.primary.dark,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background-color 0.2s ease-out',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },

  iconWrapper: {
    '&:hover': {
      color: 'red',
      '& > div > div': {
        width: 40,
      },
    },
  },
  icon: {
    width: 30,
  },
  arrow: {
    position: 'relative',
    width: '100%',
    height: 2,
    backgroundColor: theme.palette.common.white,
    transition: 'width 0.2s ease-out',
    '&::after, &::before': {
      content: '""',
      position: 'absolute',
      width: 25,
      height: 2,
      right: -6,
      backgroundColor: theme.palette.common.white,
    },
    '&::after': {
      top: -8,
      transform: 'rotate(45deg)',
    },
    '&::before': {
      top: 8,
      transform: 'rotate(-45deg)',
    },
  },

  logo: {
    display: 'flex',
    width: '40%',
    justifyContent: 'center',
    '& > img': {
      maxHeight: '100%',
      maxWidth: 230,
      paddingBottom: 12,
      paddingRight: 12,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'flex-end',
    },
  },

  contentWrapper: {
    paddingTop: 87,
  },

  counter: {
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
    '& div': {
      fontSize: 100,
      lineHeight: '100px',
      fontFamily: 'Visby CF, sans-serif',
      fontWeight: 700,
      [theme.breakpoints.down('md')]: {
        fontSize: 70,
        lineHeight: '70px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 33,
        lineHeight: '45px',
      },
    },
  },

  content: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 540,
      marginRight: 200,
    },
    '& h2': {
      color: 'white',
      fontFamily: 'Visby CF, sans-serif',
    },
  },

  heading: {
    marginBottom: theme.spacing(3),
  },

  text: {
    fontSize: 16,
    marginBottom: theme.spacing(5),
  },
}));

const Image = ({ fileName, alt = '' }) => {
  const imageData = useStaticQuery(
    graphql`
      query {
        allFile(filter: { extension: { regex: "/png|jpg/" } }) {
          nodes {
            base
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    `
  );

  const imageMatch = imageData.allFile.nodes.find(
    (n: any) => n.base === fileName
  ).childImageSharp.gatsbyImageData;

  const image = getImage(imageMatch);

  return image ? (
    <GatsbyImage
      image={image}
      alt={alt}
      objectFit="contain"
      objectPosition="50% 50%"
    />
  ) : null;
};

const CaseStudies: React.FC = () => {
  const caseStudyStyles = useCaseStudyStyles();

  // Reminder: Update gatsby-plugin-page-creator options
  // in gatsby-config.ts to stop ignoring case studies.
  const enableCustomerCaseStudies = false;

  return (
    <Paper className={caseStudyStyles.caseStudyRoot}>
      {caseStudies.map((casestudy, index) => (
        <Container
          maxWidth={false}
          disableGutters
          className={caseStudyStyles.caseStudy}
          key={index}
        >
          {/* Image */}
          <Box
            className={`${caseStudyStyles.imageWrapper} ${
              index % 2 > 0 && caseStudyStyles.imageRight
            }`}
          >
            <div className={`${caseStudyStyles.caseStudy}__image`}>
              <Image fileName={casestudy.banner} alt="" />
            </div>
            {enableCustomerCaseStudies && (
              <Box className={caseStudyStyles.bannerContent}>
                <Box className={caseStudyStyles.callToAction}>
                  <Typography variant="subtitle2" component="p">
                    view case study
                  </Typography>
                  <Link
                    to={casestudy.link}
                    className={caseStudyStyles.iconWrapper}
                    aria-label="view case study"
                  >
                    <div className={caseStudyStyles.icon}>
                      <div className={caseStudyStyles.arrow}></div>
                    </div>
                  </Link>
                </Box>
                {casestudy.logo.length > 0 && (
                  <Grid className={caseStudyStyles.logo}>
                    <img
                      src={casestudy.logo}
                      alt=""
                      width={casestudy.logoWidth}
                      height={casestudy.logoHeight}
                    />
                  </Grid>
                )}
              </Box>
            )}
          </Box>

          <Container>
            <Grid
              container
              spacing={0}
              justify={!(index % 2) ? 'flex-end' : 'flex-start'}
              className={caseStudyStyles.contentWrapper}
            >
              {/* Counter */}
              <Grid item xs={2} className={caseStudyStyles.counter}>
                <Typography component="div">
                  {(index + 1).toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}
                </Typography>
              </Grid>

              {/* Content */}
              <Grid item xs={10} lg={6}>
                <Grid className={caseStudyStyles.content}>
                  <Typography variant="h2" gutterBottom={true}>
                    {casestudy.heading}
                  </Typography>
                  <Typography
                    variant="h3"
                    color="textSecondary"
                    gutterBottom={true}
                  >
                    problem
                  </Typography>
                  <Typography
                    variant="body2"
                    className={caseStudyStyles.heading}
                  >
                    {casestudy.problem.heading}
                  </Typography>
                  <Typography variant="body2" className={caseStudyStyles.text}>
                    {casestudy.problem.description}
                  </Typography>
                  <Typography
                    variant="h3"
                    color="textSecondary"
                    gutterBottom={true}
                  >
                    solution
                  </Typography>
                  <Typography
                    variant="body2"
                    className={caseStudyStyles.heading}
                  >
                    {casestudy.solution.heading}
                  </Typography>
                  <Typography variant="body2" className={caseStudyStyles.text}>
                    {casestudy.solution.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Container>
      ))}
    </Paper>
  );
};

export default CaseStudies;
