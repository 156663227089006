import { Container, Grid, Typography, Box } from '@material-ui/core';
import React from 'react';
import LightMode from '../components/global/light-mode';
import Layout from '../components/global/layout';
import SEO from '../components/seo';
import VerticalPadding from '../components/global/vertical-padding';
import { makeStyles } from '@material-ui/core/styles';
import PageBanner from '../components/layouts/page-banner';
import CaseStudies from '../components/layouts/case-studies';
import Testimonial from '../components/layouts/testimonial';
import CallToAction from '../components/layouts/call-to-action';
import Button from '../components/mui/button';
import WCLogo from '../images/svg/wesleyclover-logo.svg';

const useButtonStyles = makeStyles(() => ({
  buttonWrapper: {
    '& > a': {
      marginBottom: 20,
      minWidth: 240,
    },
  },
}));

const UseCases: React.FC = () => {
  const buttonStyles = useButtonStyles();

  return (
    <Layout>
      <SEO
        title="Web Operations and Services Use Cases"
        description="Integrated web operations that eliminate disintegrated web operations processes that bring advanced digital experiences to your audience."
      />
      <PageBanner
        maxWidth={980}
        heading="Develop, Launch, Manage, and Iterate – Better – Faster"
        imageSrc="use-cases-banner.jpg"
      />

      <LightMode>
        <VerticalPadding lg>
          <Container>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item xs={12} md={7}>
                <Typography variant="h2">
                  Eliminate Disintegrated and Time-Consuming Web Operations
                  Processes
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="subtitle1" component="p">
                  Bring advanced digital experiences to your audience more
                  quickly and efficiently with web operations service offerings
                  that provide one point of contact for all of your ongoing web
                  operations needs.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </VerticalPadding>
      </LightMode>

      <CaseStudies />

      <Testimonial
        author="Steve Langford, VP of Marketing at Wesley Clover International"
        logo={WCLogo}
      >
        With Marvel <span style={{ fontSize: '0.8em' }}>&amp;</span> Snap, I
        know I have access to the latest skills for anything web-related.
      </Testimonial>

      <CallToAction imageSrc="call-to-action-man-typing.jpg">
        <Typography
          variant="h2"
          color="textSecondary"
          gutterBottom={true}
          style={{ maxWidth: '9em' }}
        >
          Get Worry-Free Web Operations
        </Typography>
        <Typography
          variant="body2"
          style={{ fontSize: 24, marginTop: 40, marginBottom: 50 }}
        >
          Develop, launch, manage, and iterate digital marketing assets faster.
          And bring advanced digital experiences to your audience more quickly
          and efficiently.
        </Typography>
        <Box className={buttonStyles.buttonWrapper}>
          <Button
            size="large"
            variant="outlined"
            color="secondary"
            to="/contact/"
            style={{ marginRight: 20 }}
          >
            Let&apos;s talk
          </Button>
          <Button
            size="large"
            variant="outlined"
            color="secondary"
            to="/pricing/"
          >
            explore pricing
          </Button>
        </Box>
      </CallToAction>
    </Layout>
  );
};

export default UseCases;
